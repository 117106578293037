<div fxLayout="column" fxLayoutAlign="center center" style="height: 100%;" fxLayoutGap="30px">
  <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="width: 1200px;">
    <div class="autologin-item" fxFlex="32%" fxLayout="column" fxLayoutAlign="center center" *ngFor="let role of roles" (click)="loginAs(role.email)" [style.background]="role.color">
      <span class="autologin-title">Login as</span>
      <span class="autologin-role">
        {{role.title}}
      </span>
    </div>
  </div>
</div>
