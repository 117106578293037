import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from 'src/app/modules/security/services/user.service';
import { ROUTE_TRANSITION } from '../../../app.animation';
import { ContentsService } from '../../contents/services/contents.service';
import { AuthenticationService } from '../authentication.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'elastic-autologin',
  templateUrl: './autologin.component.html',
  styleUrls: ['./autologin.component.scss'],
  animations: [...ROUTE_TRANSITION],
  host: { '[@routeTransition]': '' }
})
export class AutologinComponent implements OnInit {

  public isLoading: false;
  public hide = true;
  public password = environment.autoLoginPassword;
  public roles = [
    {
      role: 'doctor',
      email: 'doctortest@ligasoft.com',
      title: 'DOCTOR',
      color: '#4363d8',
    },
    {
      role: 'start',
      email: 'testuser.start@test.com',
      title: 'START',
      color: '#e6194B',
    },
    {
      role: 'project',
      email: 'testuser.project@test.com',
      title: 'PROJECT',
      color: '#f58231',
    },
    {
      role: 'segmentation',
      email: 'testuser.segmentation@test.com',
      title: 'SEGMENTATION',
      color: '#3cb44b',
    },
    {
      role: 'super_admin',
      email: 'superadmin@ligasoft.com',
      title: 'SUPER ADMIN',
      color: '#ffe119',
    },
    {
      role: 'executive_project',
      email: 'executive.project@ligasoft.com',
      title: 'EXECUTIVE PROJECT',
      color: '#911eb4',
    },
    {
      role: 'export',
      email: 'testuser.export@test.com',
      title: 'EXPORT',
      color: '#bfef45',
    },
    {
      role: 'editor',
      email: 'testeditor@ligasoft.com',
      title: 'EDITOR',
      color: '#f032e6',
    },
    {
      role: 'customer_service',
      email: 'customer.service@test.com',
      title: 'CUSTOMER SERVICE',
      color: '#42d4f4',
    },
  ];

  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private _translocoService: TranslocoService,
    private _contentService: ContentsService,
    private _userService: UserService
  ) { }

  ngOnInit() {

  }

  loginAs(email) {
    if (environment.production) {
      return false;
    }
    this.authService.autoLoginUser(email, this.password, false).subscribe(async result => {
      if (result) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree(['/dashboard'])
        );
        window.open(url, '_blank');
      }
    });
  }

}
