<div class="card state-widget" [style.background]="backgroundColor" [style.color]="textColor">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxLayout="column" fxLayoutAlign="end" fxLayoutGap="5px">
        <div *ngIf="property!=''" class="property">{{ property }}</div>
        <span [innerHtml]="value"></span>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between" *ngIf="icon!=''">
        <mat-icon class="icon" fxFlexAlign="end">{{ icon }}</mat-icon>
        <div class="change" fxLayout="row" fxLayoutAlign="end center" fxFlexAlign="end">
        </div>
      </div>
    </div>
  </div>
