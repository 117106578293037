import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutologinComponent } from './autologin.component';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import {
  MatIconModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSliderModule,
  MatSlideToggleModule
} from '@angular/material';
import { ToolbarModule } from '../../../core/toolbar/toolbar.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSliderModule,
    ToolbarModule,
    MatIconModule,
    MatSlideToggleModule
  ],
  declarations: [AutologinComponent],
  exports: [AutologinComponent]
})
export class AutologinModule { }
