<div *accessControl="{id:item.getPermission(), userGroup:item.getUserGroups()}">
  <div class="navigation-item-container" *ngIf="item.hasSubItems() && !item.isHidden" routerLinkActive="dropdown-item-active">
    <a class="navigation-item" fxLayout="row" fxLayoutAlign="start center" *accessControl="{id:item.getPermission()}"
       [class.disabled]="isDisabled()"
       [matBadge]="getCountForContent(item)" [matBadgeHidden]="getCountForContent(item) == 0" matBadgePosition="after"
       matBadgeColor="warn"
    >
      <img [src]="item.customIconPath" alt="" *ngIf="item.iconPosition == 'left' && item.customIcon">
      <mat-icon *ngIf="item.iconPosition == 'left' && !item.customIcon" class="icon">{{ item.icon }}</mat-icon>
      <span class="name">{{ item.name | transloco }}</span>
      <img [src]="item.customIconPath" alt="" *ngIf="item.iconPosition == 'right' && item.customIcon">
      <mat-icon *ngIf="item.iconPosition == 'right' && !item.customIcon" class="icon">{{ item.icon }}</mat-icon>
    </a>

    <div class="dropdown" *ngIf="item.hasSubItems() && !isDisabled()">
      <elastic-toolbar-navigation-dropdown-item
        class="dropdown-item-container" *ngFor="let subItem of item.subItems" [item]="subItem"
        [currentlyOpen]="currentlyOpen"></elastic-toolbar-navigation-dropdown-item>
    </div>
  </div>
</div>

<div class="navigation-item-container" *ngIf="!item.hasSubItems() && !item.isHidden">
  <div *ngIf="!item.isButton()">
    <a [routerLink]="isDisabled()? null : item.route" routerLinkActive="active"
       [routerLinkActiveOptions]="item.routerLinkActiveOptions" [class.disabled]="isDisabled()"
       class="navigation-item" fxLayout="row" fxLayoutAlign="start center" matRipple
       *accessControl="{id:item.getPermission(), userGroup:item.getUserGroups()}">
      <img [src]="item.customIconPath" alt="" *ngIf="item.iconPosition == 'left' && item.customIcon">
      <mat-icon *ngIf="item.iconPosition == 'left' && !item.customIcon" class="icon">{{ item.icon }}</mat-icon>
      <span class="name">{{ item.name | transloco }}</span>
      <img [src]="item.customIconPath" alt="" *ngIf="item.iconPosition == 'right' && item.customIcon">
      <mat-icon *ngIf="item.iconPosition == 'right' && !item.customIcon" class="icon">{{ item.icon }}</mat-icon>
    </a>
  </div>

  <div *ngIf="item.isButton()">
    <a (click)="openCreateModal(edtiCreateModal)"
       class="navigation-item" fxLayout="row" fxLayoutAlign="start center" matRipple
       *accessControl="{id:item.getPermission(), userGroup:item.getUserGroups()}">
      <img [src]="item.customIconPath" alt="" *ngIf="item.iconPosition == 'left' && item.customIcon">
      <mat-icon *ngIf="item.iconPosition == 'left' && !item.customIcon" class="icon">{{ item.icon }}</mat-icon>
      <span class="name">{{ item.name | transloco }}</span>
      <img [src]="item.customIconPath" alt="" *ngIf="item.iconPosition == 'right' && item.customIcon">
      <mat-icon *ngIf="item.iconPosition == 'right' && !item.customIcon" class="icon">{{ item.icon }}</mat-icon>
    </a>
  </div>

</div>


<ng-template #edtiCreateModal let-modal="modal">
  <nuvolaweb-create-update-user
    (createUpdate)="createUpdateHandler($event)"
    [isDoctorCreating]="true"
  >
  </nuvolaweb-create-update-user>
</ng-template>

