import * as tslib_1 from "tslib";
import { User } from '../../modules/security/models/user.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { httpHeaders } from '../../../environments/httpHeaders';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../modules/security/services/user.service";
import * as i3 from "@auth0/angular-jwt";
const apiUrl = environment.apiUrl;
const headers = httpHeaders.headers;
export class AuthenticationService {
    constructor(_http, userService, jwtHelper) {
        this._http = _http;
        this.userService = userService;
        this.jwtHelper = jwtHelper;
        this.emailToChangePassword = null;
        this.user = this.userService.readUserFromLocalStorage();
    }
    loginUser(username, password, remember) {
        const endPoint = '/api/token/';
        const request = {
            username: username,
            password: password,
            rememberMe: remember == null ? false : remember
        };
        return this._http.post(apiUrl + endPoint, request, { headers: headers, observe: 'response' }).pipe(map(response => {
            this.user = new User(response.body);
            if (this.user && this.user.accessToken) {
                localStorage.setItem('nuvolaUser', JSON.stringify(this.user));
                localStorage.setItem('nuvola_session_token', this.user.accessToken);
                localStorage.setItem('nuvola_refresh_token', this.user.refreshToken);
            }
            return this.user;
        }));
    }
    autoLoginUser(username, password, remember) {
        const endPoint = '/api/token/AutoLogin';
        const autoLoginRequest = {
            username: username,
            password: password,
            rememberMe: remember
        };
        return this._http.post(apiUrl + endPoint, autoLoginRequest, { headers: headers, observe: 'response' }).pipe(map(response => {
            this.user = new User(response.body);
            if (this.user && this.user.accessToken) {
                localStorage.setItem('nuvolaUser', JSON.stringify(this.user));
                localStorage.setItem('nuvola_session_token', this.user.accessToken);
                localStorage.setItem('nuvola_refresh_token', this.user.refreshToken);
            }
            return this.user;
        }));
    }
    logOutUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.user && this.user.accessToken) {
                const endPoint = '/api/token/logout';
                const response = yield this._http.post(apiUrl + endPoint, null, { headers: headers, observe: 'response' }).toPromise();
                console.log('LogOut:' + response.status + ' ' + response.statusText);
            }
            this.clearStorage();
        });
    }
    clearStorage() {
        localStorage.removeItem('nuvolaUser');
        localStorage.removeItem('nuvola_session_token');
        localStorage.removeItem('nuvola_refresh_token');
    }
    isLoggedIn() {
        this.user = this.userService.readUserFromLocalStorage();
        return !!this.user && (this.user.accessToken);
    }
    isTokenExpired() {
        return this.jwtHelper.isTokenExpired(this.user.accessToken);
    }
    getToken() {
        return this.user.accessToken;
    }
    getRefreshToken() {
        return this.user.refreshToken;
    }
    refreshToken() {
        let endPoint = '/api/token/refresh-token';
        let reques = {
            'refreshToken': this.user.refreshToken
        };
        return this._http.post(apiUrl + endPoint, reques, { headers: headers, observe: 'response' }).pipe(map((resp) => {
            console.log('Refresh Token: ' + resp.status + ' ' + resp.statusText);
            this.user.accessToken = resp.body.accessToken;
            this.user.refreshToken = resp.body.refreshToken;
            localStorage.removeItem('nuvolaUser');
            localStorage.removeItem('nuvola_session_token');
            localStorage.removeItem('nuvola_refresh_token');
            localStorage.setItem('nuvolaUser', JSON.stringify(this.user));
            localStorage.setItem('nuvola_session_token', this.user.accessToken);
            localStorage.setItem('nuvola_refresh_token', this.user.refreshToken);
            return this.user.accessToken;
        }, error => {
            return false;
        }));
    }
    refreshTokenTrigger() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return yield this.refreshToken().toPromise();
        });
    }
    getExpireTokenTime() {
        this.user = this.userService.readUserFromLocalStorage();
        return this.jwtHelper.getTokenExpirationDate(this.user.accessToken);
    }
    isUserInRole(role) {
        if (Array.isArray(role)) {
            return role.includes(this.user.role);
        }
        else {
            return this.user.role === role;
        }
    }
    isUserInGroup(userGroup) {
        if (Array.isArray(userGroup)) {
            return userGroup.includes(this.user.userGroup);
        }
        else {
            return this.user.userGroup === userGroup;
        }
    }
    forgorPassword(email) {
        const endPoint = '/api/admin/forgotpassword';
        let request = {
            email: email
        };
        return this._http.post(apiUrl + endPoint, request, { headers: headers });
    }
    sendConfirmEmailToDoctor(idAdmin) {
        const endPoint = '/api/admin/doctor/sendconfirmemail';
        let request = {
            idAdmin: idAdmin
        };
        return this._http.post(apiUrl + endPoint, request, { headers: headers });
    }
    checkResetPassword(code) {
        const endPoint = '/api/admin/checkresetpassword';
        let request = {
            resetToken: code
        };
        return this._http.post(apiUrl + endPoint, request, { headers: headers });
    }
    resetPassword(code, password) {
        const endPoint = '/api/admin/resetpassword';
        let request = {
            resetToken: code,
            newPassword: password
        };
        return this._http.post(apiUrl + endPoint, request, { headers: headers });
    }
    changePassword(request) {
        const endPoint = '/api/Admin/ChangePassword';
        return this._http.post(`${apiUrl}${endPoint}`, request, { headers: headers });
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.UserService), i0.ɵɵinject(i3.JwtHelperService)); }, token: AuthenticationService, providedIn: "root" });
