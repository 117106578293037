export class SidenavItem {
  name: string;
  icon: string;
  iconPosition: string;
  route: any;
  parent: SidenavItem;
  subItems: SidenavItem[];
  position: number;
  badge: string;
  customIcon: boolean;
  isHidden: boolean;
  customIconPath: string;
  badgeColor: string;
  customClass: string;
  routerLinkActiveOptions: any;
  forPermission: string|string[];
  isItemButton: boolean;
  forGroups: string|string[];
  contentName: string;
  badgeContent: number;
  externalLink: string;

  constructor(model: any = null) {
    if (model) {
      this.name = model.name;
      this.icon = model.icon;
      this.route = model.route;
      this.parent = model.parent;
      this.subItems = this.mapSubItems(model.subItems);
      this.position = model.position;
      this.badge = model.badge;
      this.badgeColor = model.badgeColor;
      this.customIcon = model.customIcon;
      this.isHidden = model.isHidden;
      this.customIconPath = model.customIconPath;
      this.customClass = model.customClass;
      this.routerLinkActiveOptions = model.routerLinkActiveOptions ? model.routerLinkActiveOptions : { exact: false };
      this.forPermission = model.forPermission;
      this.forGroups = model.forGroups;
      this.contentName = model.contentName;
      this.badgeContent = model.badgeContent;
      this.externalLink = model.externalLink;
      this.iconPosition = 'left';
      this.isItemButton = !!model.isButton;
      if (model.iconPosition) {
        this.iconPosition = model.iconPosition;
      }
    }
  }

  hasSubItems() {
    if (this.subItems) {
      return this.subItems.length > 0;
    }
    return false;
  }

  getPermission() {
    return this.forPermission;
  }

  getUserGroups() {
    return this.forGroups;
  }

  hasParent() {
    return !!this.parent;
  }

  isButton() {
    return this.isItemButton;
  }

  mapSubItems(list: SidenavItem[]) {
    if (list) {
      list.forEach((item, index) => {
        list[index] = new SidenavItem(item);
      });

      return list;
    }
  }

  routeIsFunction() {
    return this.route instanceof Function || typeof this.route === 'function';
  }

  generateLetterIcon() {
    const words = this.name.split(' ');

    if (words.length > 1) {
      return words[0].charAt(0).toUpperCase() + words[1].charAt(0).toLowerCase();
    } else {
      return this.name.charAt(0).toUpperCase() + this.name.charAt(1).toLowerCase();
    }
  }

  getBadgeContentValue() {
    if (this.badgeContent) {
      return this.badgeContent;
    }

    return 0;
  }
}
