import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginModule } from './login/login.module';
import { RegisterModule } from './register/register.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthRoutes } from './auth.routing';
import { SessionExpiredModule } from './session-expired/session-expired.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { ChangePasswordModule } from './change-password/change-password.module';
import { WebviewerModule } from './webviewer/webviewer.module';
import { RegistrationConfirmationModule } from './registration-confirmation/registration-confirmation.module';
import { AutologinModule } from './autologin/autologin.module';

@NgModule({
  imports: [
    CommonModule,
    LoginModule,
    AutologinModule,
    RegisterModule,
    ForgotPasswordModule,
    MatSnackBarModule,
    AuthRoutes,
    SessionExpiredModule,
    ResetPasswordModule,
    ChangePasswordModule,
    WebviewerModule,
    RegistrationConfirmationModule
  ],
  declarations: []
})
export class AuthModule { }
