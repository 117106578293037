import { EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { httpHeaders } from '../../../environments/httpHeaders';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const apiURL = environment.apiUrl;
const headers = httpHeaders.headers;
export class PatientService {
    constructor(_http) {
        this._http = _http;
        this.doctorsLoaded = new EventEmitter();
        this.treatmentsLoaded = new EventEmitter();
    }
    doctorsIsLoaded() {
        this.doctorsLoaded.emit(true);
    }
    treatmentIsLoaded() {
        this.treatmentsLoaded.emit(true);
    }
    getDoctors() {
        const endPoint = '/api/admin/byrole/DO';
        return this._http.get(apiURL + endPoint, { headers: headers }).pipe(map((resp) => {
            return resp;
        }));
    }
    getDoctorsLite() {
        const endPoint = '/api/admin/byrolelite/DO';
        return this._http.get(apiURL + endPoint, { headers: headers }).pipe(map((resp) => {
            return resp;
        }));
    }
    createTreatment(request) {
        const endPoint = '/api/treatment';
        return this._http.put(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    getTreatment(id) {
        const endPoint = `/api/treatment/${id}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    setSegmentationDone(idTreatment) {
        const endPoint = `/api/Treatment/SetSegmentationFlag/${idTreatment}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    getDoctor(id) {
        const endPoint = `/api/admin/${id}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    updateCutData(data) {
        const endPoint = `/api/Treatment/UpdateCutData/`;
        return this._http.post(`${apiURL}${endPoint}`, data, { headers: headers });
    }
    submitServiceSheet(data) {
        const endPoint = `/api/Treatment/ServiceSheetInfo/`;
        return this._http.post(`${apiURL}${endPoint}`, data, { headers: headers });
    }
    getServiceSheet(idTreatment) {
        const endPoint = `/api/Treatment/ServiceSheetInfo/${idTreatment}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    getDoctorLite(id) {
        const endPoint = `/api/Admin/Lite/${id}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    updatePatientData(request) {
        const endPoint = '/api/treatment/updatepatientdata';
        return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    saveVideoRequested(idTreatment, videoRequestedValue) {
        const endPoint = `/api/treatment/UpdateVideoRequested/${idTreatment}/${videoRequestedValue}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    saveTreatment(request) {
        const endPoint = '/api/treatment';
        return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    gotoNextPhaseRevision(request) {
        const endPoint = '/api/treatmentplan/gotonext';
        return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    updateDoctorNotes(request) {
        const endPoint = '/api/admin/doctor/updatedoctorinternalnotes';
        return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    getServiceSheetFile(idTreatment, language, idAdmin) {
        const endPoint = `/api/TreatmentPlan/ServiceSheet/${idTreatment}/${language}/${idAdmin}`;
        return this._http.get(`${apiURL}${endPoint}`, { observe: 'response', responseType: 'blob' });
    }
    changeStatus(reason, treatmentId, endpoint, request) {
        const endPoint = `/api/treatment/changestatus/${endpoint}`;
        request = request ? request : {
            idTreatment: +treatmentId,
            reason: reason
        };
        return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    getTreatmentPlanPdf(idTreatment, language) {
        const endPoint = `/api/TreatmentPlan/Pdf/${idTreatment}/${language}`;
        return this._http.get(`${apiURL}${endPoint}`, { observe: 'response', responseType: 'blob' });
    }
    getWorkSheetPdf(idTreatment, language) {
        const endPoint = `/api/TreatmentPlan/WorkSheetPdf/${idTreatment}/${language}`;
        return this._http.get(`${apiURL}${endPoint}`, { observe: 'response', responseType: 'blob' });
    }
    deleteTreatment(idTreatment) {
        const endPoint = '/api/treatment';
        return this._http.delete(`${apiURL}${endPoint}/${idTreatment}`, { headers: headers });
    }
    updateWaitingForSTL(value, idTreatment) {
        const endPoint = `/api/treatment/updatewaitingforstl/${value}/${idTreatment}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    updateTreatmentPlanLevel(idTreatment, value) {
        const endPoint = `/api/treatmentplan/updatelevel/${idTreatment}/${value}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    updateTreatmentPlanMandatoryPhase(data) {
        const endPoint = `/api/treatmentplan/updateTreatmentPlanMandatoryPhase`;
        return this._http.post(`${apiURL}${endPoint}`, data, { headers: headers });
    }
    updateTreatmentPlanFieldBool(request) {
        const endPoint = `/api/treatmentplan/updatetreatmentplanfield`;
        return this._http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
    migrateFromQuote(idQuote) {
        const endPoint = `/api/treatment/migratefromquote/${idQuote}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    deleteQuote(idQuote, isTellMeYes) {
        const endPoint = isTellMeYes ? `/api/TellMeYes/${idQuote}` : `/api/quote/${idQuote}`;
        return this._http.delete(`${apiURL}${endPoint}`, { headers: headers });
    }
    confirmQuote(idQuote, isTellMeYes) {
        const endPoint = isTellMeYes ? `/api/TellMeYes/confirmTellMeYes/${idQuote}` : `/api/quote/confirmquote/${idQuote}`;
        return this._http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
}
PatientService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PatientService_Factory() { return new PatientService(i0.ɵɵinject(i1.HttpClient)); }, token: PatientService, providedIn: "root" });
