/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./autologin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/common";
import * as i6 from "./autologin.component";
import * as i7 from "@angular/router";
import * as i8 from "../authentication.service";
import * as i9 from "@angular/forms";
import * as i10 from "@angular/material/snack-bar";
import * as i11 from "@ngneat/transloco";
import * as i12 from "../../contents/services/contents.service";
import * as i13 from "../../../modules/security/services/user.service";
var styles_AutologinComponent = [i0.styles];
var RenderType_AutologinComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AutologinComponent, data: { "animation": [{ type: 7, name: "routeTransition", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { width: "100%", height: "100%", display: "block", position: "absolute" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { width: "100%", height: "100%", display: "block", position: "absolute" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: "0" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: "1" }, offset: null }, timings: "0.5s linear" }], options: null }, { type: 1, expr: ":leave", animation: [], options: null }], options: {} }] } });
export { RenderType_AutologinComponent as RenderType_AutologinComponent };
function View_AutologinComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "autologin-item"], ["fxFlex", "32%"], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], [[4, "background", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.loginAs(_v.context.$implicit.email) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "autologin-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Login as"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "autologin-role"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, [" ", " "]))], function (_ck, _v) { var currVal_1 = "column"; _ck(_v, 1, 0, currVal_1); var currVal_2 = "center center"; _ck(_v, 2, 0, currVal_2); var currVal_3 = "32%"; _ck(_v, 3, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.color; _ck(_v, 0, 0, currVal_0); var currVal_4 = _v.context.$implicit.title; _ck(_v, 7, 0, currVal_4); }); }
export function View_AutologinComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["fxLayout", "column"], ["fxLayoutAlign", "center center"], ["fxLayoutGap", "30px"], ["style", "height: 100%;"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i4.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["fxLayout", "row wrap"], ["fxLayoutAlign", "space-between center"], ["style", "width: 1200px;"]], null, null, null, null, null)), i1.ɵdid(5, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(6, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AutologinComponent_1)), i1.ɵdid(8, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "30px"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "center center"; _ck(_v, 3, 0, currVal_2); var currVal_3 = "row wrap"; _ck(_v, 5, 0, currVal_3); var currVal_4 = "space-between center"; _ck(_v, 6, 0, currVal_4); var currVal_5 = _co.roles; _ck(_v, 8, 0, currVal_5); }, null); }
export function View_AutologinComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "elastic-autologin", [], [[40, "@routeTransition", 0]], null, null, View_AutologinComponent_0, RenderType_AutologinComponent)), i1.ɵdid(1, 114688, null, 0, i6.AutologinComponent, [i7.Router, i8.AuthenticationService, i9.FormBuilder, i10.MatSnackBar, i11.TranslocoService, i12.ContentsService, i13.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var AutologinComponentNgFactory = i1.ɵccf("elastic-autologin", i6.AutologinComponent, View_AutologinComponent_Host_0, {}, {}, []);
export { AutologinComponentNgFactory as AutologinComponentNgFactory };
