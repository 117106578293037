import { EventEmitter } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { httpHeaders } from '../../../../../environments/httpHeaders';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
const apiURL = environment.apiUrl;
const headers = httpHeaders.headers;
export class ChatService {
    constructor(http) {
        this.http = http;
        this.updateMessageTime = new EventEmitter();
    }
    setUpdateMessageTime() {
        this.updateMessageTime.emit(new Date().getTime());
    }
    getMessages(treatmentId, counter, chatType) {
        let endPoint = '';
        if (chatType === 'Q') {
            endPoint = `/api/quote/chat/${treatmentId}/${counter}`;
        }
        else if (chatType === 'T') {
            endPoint = `/api/treatment/chat/${treatmentId}/${counter}`;
        }
        else if (chatType === 'TMY') {
            endPoint = `/api/TellMeYes/chat/${treatmentId}/${counter}`;
        }
        else if (chatType === 'I') {
            endPoint = `/api/treatment/incomplete/chat/${treatmentId}/${counter}`;
        }
        return this.http.get(`${apiURL}${endPoint}`, { headers: headers });
    }
    sendMessage(id, message, chatType) {
        let endPoint = '';
        let request;
        if (chatType === 'Q') {
            endPoint = `/api/quote/chat/`;
            request = {
                'message': message,
                'idQuote': id
            };
        }
        else if (chatType === 'T') {
            endPoint = `/api/treatment/chat/`;
            request = {
                'message': message,
                'idTreatment': id
            };
        }
        else if (chatType === 'TMY') {
            endPoint = `/api/tellmeyes/chat/`;
            request = {
                'message': message,
                'idTellMeYes': id
            };
        }
        else if (chatType === 'I') {
            endPoint = `/api/treatment/incomplete/chat/`;
            request = {
                'message': message,
                'idTreatment': id
            };
        }
        return this.http.post(`${apiURL}${endPoint}`, request, { headers: headers });
    }
}
ChatService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ChatService_Factory() { return new ChatService(i0.ɵɵinject(i1.HttpClient)); }, token: ChatService, providedIn: "root" });
